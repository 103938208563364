*{
    font-family: "Roboto",sans-serif;
    letter-spacing: 1px;
}
.title{
    color:#4D5B9E;
    text-align: center;
    width: 53vw;
    font-size: 2.3vw;
    margin-top: 0;
    background-color: #000;
    padding: 1rem;
    font-weight: 800;
    
}

.container{
    width: 100%;
    height: 100vh; 
    display: flex;
    align-items: center;
    flex-direction: column;
    
    
}
hr{
    width: 100%;
    margin: 1.3rem;
}
.questions{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}
.question{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
}
.question--text{
    font-size: 1.4rem;

}
.choices{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.incorrect--input{
    display: none;
}
.choice--input{
    display: none;
}

.choice{
    width: 11rem;
    max-height:4.1rem;
    min-height: 2rem;
    border: 2px #000 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0.3rem;
    border-radius: 11px;
    font-weight: 600;
    cursor: pointer;
    text-align: center; 
}

.incorrect{
    width: 11rem;
    max-height:4.1rem;
    min-height: 2rem;
    border: 2px #000 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0.3rem;
    border-radius: 11px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;

}

.choice--input:checked +.choice{
    background-color: #4D5B9E;
}

.incorrect--input:checked +.incorrect{
    background-color: red;
}



.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.buttons{
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 2rem;
}


.questions--button{
    width: 11rem;
    height: 3rem;
    font-weight: 900;
    cursor: pointer;
    background-color: #000;
    color: #4D5B9E;
    font-size: 1rem;
    border: none;
    margin: 0 1rem;
}



.intro{
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro--title{
    color: #4D5B9E;
    font-weight: 500;
    font-size:3rem;
}

.intro--desc{
    color: #4D5B9E;
    font-weight: 800;
    font-size: 1.2rem;
    text-align: center;

    
}


.score--text{
    font-size: 1.5rem;
    color: #4D5B9E;
    font-weight: 900;
    letter-spacing: 2px;

}
.channel{
    color: #fff;
}

@media screen and (max-width:1204px){
    .title{
      font-size: 2.8vw;
      width: 55vw;
    }
}

@media screen and (max-width:840px){
    .choice,.incorrect{
        width: 17vw;
        letter-spacing: 0.5px;
        font-size: 2.3vw;
        padding: 0.4rem 0.2rem;

    }
    .intro--desc{
        font-size: 3.1vw;
    }
}
@media screen and (max-width:656px){
    .question--text{
        font-size: 4vw;
    }
    .score--text{
        font-size: 4vw;
    }
}
@media screen and (max-width:590px){
    .title{
        font-size: 4vw;
    }
}

